<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-btn
          @click="scanBill()"
          color="primary"
          dark
          class="mb-2">
          สแกนบิล
        </v-btn>

        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        width: 70,
        sortable: false
      },
      { text: 'เลชใบสั่งซื้อ', value: 'order.order_inv', sortable: false},
      { text: 'พนักงาน', value: 'employee.employee_name', sortable: false },
      { text: 'วันที่ดำเนินรายการ', value: 'created_at' },
      // { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getReport()
      },
    },
    search : {
      handler(){
        this.getReport()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getReport()
  },

  methods: {
    scanBill(){
      this.$router.push({name : 'pages-billing-print'})
    },
    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_report.employeeTypeDestroy(this.editedItem).then(res => {

        this.reports.splice(this.editedIndex, 1)
      }).catch(err => {

      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        instance_report.employeeTypeUpdate(this.editedItem)
          .then(res => {
            Object.assign(this.employees_types[this.editedIndex], this.editedItem)
          }).catch(err => {

        });
      } else {
        instance_report.employeeTypeStore(this.editedItem)
          .then(res => {
            this.employees_types.push(this.editedItem)
          }).catch(err => {

        });
      }
      this.close()
    },
    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        date : '',
        status : '',
      }

      instance_report.getReportOrderScanBill(data).then(res => {
        this.reports = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
